.app {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  width: 100%;
}

.main {
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 30px 40px rgba(0, 0, 0, .1);
}